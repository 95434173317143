import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useI18next } from 'gatsby-plugin-react-i18next';

import { useUser } from '../hooks/use-user.hook';
import { checkValidToken } from '../services/users.service';
import { setIsValidateToken } from '../states/authentication.slice';
import { OrganizationNav } from './organization-nav.component';

export const OrganizationPanelLayout = ({ children }) => {
    const dispatch = useDispatch();
    const { navigate } = useI18next();
    const { loggedIn, user } = useUser();

    useEffect(() => {
        if (user && user.accessToken && user.type === 'ORGANIZATION') {
            checkValidToken(user.accessToken)
              .then(res => {
                dispatch(setIsValidateToken(res.data));
              })
              .catch(error => console.error(error));
        } else {
            navigate('/account/register');
        }
        if (!loggedIn) navigate('/account/register');
    }, [ loggedIn, user ]);
    
    return (
        <div>
            {loggedIn && (
                <>
                    <OrganizationNav />
                    {children}
                </>
            )}
        </div>
    );
}